import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Image,
  Button,
  useColorModeValue,
  Text,
  Center,
  ButtonGroup,
  Spacer,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Outlet, Link } from 'react-router-dom';

export default function AppHeader() {
  return (
    <>
      <Center bg={useColorModeValue('gray.100', 'gray.900')} p={4}>
        <Image src={useColorModeValue('logo-no-background.png', 'logo-no-background-dark.png')} alt='a demo app' />
      </Center>
      <Flex minWidth='max-content' alignItems='center' gap='2' p='2'>
        <ButtonGroup p='2' gap='2' variant='link'>
          <Button as={Link} to="/">Home</Button>
          <Text>|</Text>
          <Button as={Link} to="/profile">Profile</Button>
          <Text>|</Text>
          <Button as={Link} to="/coolstuff">Cool Stuff</Button>
        </ButtonGroup>
        <Spacer />
        {/* <ButtonGroup gap='2'>
          <Button>Sign Up</Button>
          <Button>Log In</Button>
        </ButtonGroup> */}
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
      <hr />
      <Box p='4'>
        <Outlet />
      </Box>
    </>
  );
}