var _ = require('lodash-contrib');
const client_id = 'ifFPFC7ZONczjnOV0ZMxON04AI4Ko62t'
var redirect_uri = process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000'

const fn = {

    dec2hex: (dec) => ('0' + dec.toString(16)).substr(-2),

    generateRandomString: () => { // code_verifier
        var array = new Uint32Array(56/2);
        window.crypto.getRandomValues(array);
        return Array.from(array, fn.dec2hex).join('');
    },

    sha256: (plain) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    },

    base64urlencode: (a) => {
        var str = "";
        var bytes = new Uint8Array(a);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          str += String.fromCharCode(bytes[i]);
        }
        return btoa(str)
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
    },

    challenge_from_verifier: async (v) => {
        var hashed = await fn.sha256(v);
        var base64encoded = fn.base64urlencode(hashed);
        return base64encoded;
    },

    getAuthorizeUrl: async (response_type = 'code', prompt = null) => {
        // get from sessionStorage
        let code_verifier = sessionStorage.getItem('code_verifier')
        const code_challenge = await fn.challenge_from_verifier(code_verifier)
        const code_challenge_method = 'S256'
        const audience = 'http://localhost:8080'
        // const scope = 'sub name given_name family_name middle_name nickname preferred_username profile picture website email email_verified gender birthdate zoneinfo locale phone_number phone_number_verified address updated_a'
        const scope = 'openid profile name picture'
        // const response_type = 'code'
        const state = 'xyzABC1234'

        const params = {
            audience,
            scope,
            response_type,
            client_id,
            redirect_uri,
            code_challenge,
            code_challenge_method,
            state,
        }
        if (prompt) {
            params['prompt'] = 'none'
            params['nonce'] = 'xyzABC1234'
        }

        return 'https://login.ademoapp.com/authorize?' + _.toQuery(params)
    },

    getTokenParams: (code) => {
        return {
            grant_type:"authorization_code",
            client_id,
            code_verifier: sessionStorage.getItem('code_verifier'),
            code,
            redirect_uri
        }
    },

    getLogoutUrl: () => {
        return 'https://login.ademoapp.com/v2/logout?'
            + `client_id=${client_id}&`
            + `returnTo=${redirect_uri}`
    },



}

export default fn