import { Routes, Route } from "react-router-dom";
import AppHeader from "./components/AppHeader";
import Home from "./components/Home";
import Profile from "./components/Profile";
import NoMatch from "./components/NoMatch";
import CoolStuff from "./components/CoolStuff";


export const App = () => {

  return (
    <div>
      <Routes>
        <Route path="/" element={<AppHeader />}>
          <Route index element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/coolstuff" element={<CoolStuff />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}


{/*  */}
