import { Box, Center, Heading, Image, Img, Input, InputGroup, InputLeftAddon, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from 'axios';

export default function Profile() {

    const [user, setUser] = useState({})

  useEffect(() => {
    var options = {
      method: "GET",
      url: "https://login.ademoapp.com/userinfo",
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        'content-type': `application/json`
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        const user = response.data
        setUser(user)
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <Center border="1px">
      <VStack>
        <Heading size="lg">Profile</Heading>
        {
            Object.entries(user).map(obj => (
                <InputGroup key={obj[0]}>
                    <InputLeftAddon children={obj[0]} />
                    {
                        (obj[0] === 'picture') ? <Img px={5} src={obj[1].toString()} />
                        : <Input defaultValue={obj[1].toString()} />
                    }
                </InputGroup>
            ))
        }
      </VStack>
    </Center>
  );
}
