import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, Circle, CloseButton, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, HStack, Img, Input, InputGroup, InputLeftAddon, SimpleGrid, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, useDisclosure, VStack } from "@chakra-ui/react";
import fn from '../libs/funcs';
import axios from 'axios';
import { useEffect, useState } from "react";
import { Link, redirect, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
var _ = require('lodash-contrib');

const client_id = 'ifFPFC7ZONczjnOV0ZMxON04AI4Ko62t'

export default function Home() {

    const [authorizeUrl, setAuthorizeUrl] = useState('#')
    const [authorizeUrl2, setAuthorizeUrl2] = useState('#')
    const [logoutUrl, setLogoutUrl] = useState(fn.getLogoutUrl())
    // const [changePasswordUrl, setChangePasswordUrl] = useState('#')
    const [accessToken, setAccessToken] = useState(sessionStorage.getItem('access_token'))
    const [idToken, setIdToken] = useState(sessionStorage.getItem('id_token'))
    const [authzUrlParams, setAuthzUrlParams] = useState({})
    const [codeVerifier, setCodeVerifier] = useState(fn.generateRandomString())
    const [calledToken, setCalledToken] = useState(false)
    const [user, setUser] = useState({})
    const [loggedIn, setLoggedIn] = useState(false)
    const { isOpen: isVisible, onClose, onOpen, } = useDisclosure({ defaultIsOpen: false })
    // passwordless form stuff
    const [emailInput, setEmailInput] = useState('')
    const [emailOtpInput, setEmailOtpInput] = useState('')
    const [phoneInput, setPhoneInput] = useState('')
    
    const { search, hash } = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem('code_verifier')) {
            sessionStorage.setItem("code_verifier", codeVerifier)
        }
        const getAuthorizeUrl = async () => {
            const authorizeUrl = await fn.getAuthorizeUrl();
            setAuthorizeUrl(authorizeUrl)
            setAuthzUrlParams(_.fromQuery(authorizeUrl))
        }
        getAuthorizeUrl();
/*         const getAuthorizeUrl2 = async () => {
            const authorizeUrl2 = await fn.getAuthorizeUrl('id_token token', true);
            setAuthorizeUrl2(authorizeUrl2)
            // console.log(authorizeUrl2);
            // setAuthzUrlParams(_.fromQuery(authorizeUrl))
        }

        getAuthorizeUrl2(); */
    }, [codeVerifier])
    
    useEffect(() => {
        if (search) {
            let params = _.fromQuery(search.substring(1));
            if (params['code'] && !calledToken && !sessionStorage.getItem('access_token')) { // issue call to token endpoint
                setCalledToken(true)
                const tokenUrl = 'https://login.ademoapp.com/oauth/token'
                const config = {
                    headers:{ 'content-type': 'application/json' }
                }
                const data = fn.getTokenParams(params['code'])
                console.log('calling token endpoint');
                
                axios.post(tokenUrl, data, config)
                    .then((response) => {
                        // setRes(response.data);
                        const data = response.data
                        console.log(data);
                        setAccessToken(data.access_token)
                        setIdToken(data.id_token)

                        // store tokens in session
                        sessionStorage.setItem('access_token', data.access_token)
                        sessionStorage.setItem('id_token', data.id_token)
                        setLoggedIn(true);
                        // remove code_verifier
                        sessionStorage.removeItem('code_verifier')

                    }, (error) => {
                        console.log(error.response.data);
                    });
            } 
        }
    }, [search])

    useEffect(() => {
        try {
            var options = {
                method: "GET",
                url: "https://login.ademoapp.com/userinfo",
                headers: {
                  authorization: `Bearer ${accessToken}`,
                  'content-type': `application/json`
                },
              };
              if (accessToken) {
                  axios
                    .request(options)
                    .then(function (response) {
                      console.log(response.data);
                      const user = response.data
                      setUser(user)
                      setLoggedIn(true)
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
              }
        } catch (e) {
            console.log("exception")
            console.log(e)
        }
      }, [accessToken]);

    const handleLogout = () => {
        // cleanup
        sessionStorage.clear()
        setLoggedIn(false)
        window.location.assign(logoutUrl)
    }

    useEffect(() => {
        if (hash) {

            let params = _.fromQuery(hash)

            console.log(params);

            if (params && '#access_token' in params) {
                setAccessToken(params['#access_token'])
                sessionStorage.setItem('access_token', params['#access_token'])
            }
            if (params && 'id_token' in params) {
                setIdToken(params['id_token'])
                sessionStorage.setItem('id_token', params['id_token'])
            }

        }

    }, [hash])

    const handlePasswordlessLogin = () => {
        var options = {
            method: "POST",
            url: "https://login.ademoapp.com/passwordless/start",
            headers: {
            //   authorization: `Bearer ${accessToken}`,
              'content-type': `application/json`
            },
            data: {
                client_id,
                connection: 'email',
                email: emailInput,
                send: 'link',
                authParams: { 'scope': 'openid profile', response_type: 'id_token token', 'nonce': 'abcd123XYZ' },
            }
          };
          if (!loggedIn) {
              axios
                .request(options)
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.error(error);
                });
          }
    }

    const handleChangePasswordClose = () => {
        onClose()
    }

    const handleChangePassword = () => {
        var options = {
            method: "POST",
            url: "https://login.ademoapp.com/dbconnections/change_password",
            headers: {
            //   authorization: `Bearer ${accessToken}`,
              'content-type': `application/json`
            },
            data: {
                client_id,
                email: user["name"],
                connection: "Username-Password-Authentication"
            }
          };
          if (accessToken) {
              axios
                .request(options)
                .then(function (response) {
                  console.log(response);
                  if (response.data === "We've just sent you an email to reset your password.") {
                    onOpen()
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
          }
    }

    const isEmailInputError = emailInput === ''
    const handleEmailInputChange = (e) => setEmailInput(e.target.value)

    return (
            <SimpleGrid columns={{sm: 1, md: 2}} spacing='20px'>
                <VStack>
                    <Heading size='md'>SPA Authz Code with PKCE</Heading>
                    <HStack>
                        {loggedIn ? <></> : <Button as={Link} to={authorizeUrl}>Login</Button>}
                        {loggedIn ? <Button onClick={() => handleLogout()}>Logout</Button> : <></>}
                        {loggedIn && user["sub"] && user["sub"].toString().split('|')[0] === 'auth0' ? <Button onClick={() => handleChangePassword()}>Change Password</Button> : <></>}
                    </HStack>
                    {isVisible ? (
                        <Alert status='info'>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>Info!</AlertTitle>
                                <AlertDescription>We've just sent you an email to reset your password.</AlertDescription>
                            </Box>
                            <CloseButton alignSelf='flex-start' position='relative' right={-1} top={-1} onClick={() => handleChangePasswordClose()} />
                        </Alert>
                    ) : <></>}
                    { 
                        Object.entries(user).map(obj => (
                            <InputGroup key={obj[0]}>
                                <InputLeftAddon children={obj[0]} />
                                {
                                    (obj[0] === 'picture') ? <Img px={5} src={obj[1].toString()} />
                                    : <Input defaultValue={obj[1].toString()} />
                                }
                            </InputGroup>
                        ))
                    }
                </VStack>
                <Box>
                    <Accordion defaultIndex={[3]} allowMultiple>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>code_verifier</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Input readOnly defaultValue={sessionStorage.getItem('code_verifier')} />
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>/authorize URL</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Box w={600} as="pre">{JSON.stringify(queryString.parseUrl(authorizeUrl), null, 2)}</Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>Access Token</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            <Box w={600} as="pre">{JSON.stringify(accessToken ? jwtDecode(accessToken) : null, null, 2)}</Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>ID Token</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            <Box w={600} as="pre">{JSON.stringify(idToken ? jwtDecode(idToken) : null, null, 2)}</Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
                <hr /><hr />
                <VStack>
                    <Heading size='md'>SPA Passwordless</Heading>
                    <FormControl>
                        <HStack pt={4}>
                            {/* <Circle bg='purple.700' color='white' size='30px'>1</Circle> */}
                            <FormLabel textAlign='end' w='100px'>Email: </FormLabel>
                            <Input w='300px' type='email' value={emailInput} onChange={handleEmailInputChange} />
                            <Button isDisabled={isEmailInputError} onClick={() => handlePasswordlessLogin()}>Send email</Button>
                        </HStack>
                        {!isEmailInputError ? (
                            <FormHelperText> Enter the email you'd like to receive the password link on. </FormHelperText>
                        ) : (
                            <FormErrorMessage>Email is required.</FormErrorMessage>
                        )}
                    </FormControl>
                    {/* <FormControl isInvalid={isEmailInputError}>
                        <HStack>
                            <FormLabel>Email</FormLabel>
                            <Input type='email' value={emailInput} onChange={handleEmailInputChange} />
                            <Button onClick={() => handlePasswordlessLogin()}>Send email</Button>
                        </HStack>
                        {!isEmailInputError ? (
                            <FormHelperText> Enter the email you'd like to receive the password link on. </FormHelperText>
                        ) : (
                            <FormErrorMessage>Email is required.</FormErrorMessage>
                        )}
                    </FormControl> */}
                    
                        
                        {/* {loggedIn ? <Button onClick={() => handleLogout()}>Logout</Button> : <></>}
                        {loggedIn && user["sub"] && user["sub"].toString().split('|')[0] === 'auth0' ? <Button onClick={() => handleChangePassword()}>Change Password</Button> : <></>} */}
                    
                    {/* {isVisible ? (
                        <Alert status='info'>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>Info!</AlertTitle>
                                <AlertDescription>We've just sent you an email to reset your password.</AlertDescription>
                            </Box>
                            <CloseButton alignSelf='flex-start' position='relative' right={-1} top={-1} onClick={() => handleChangePasswordClose()} />
                        </Alert>
                    ) : <></>} */}
                    { 
                        Object.entries(user).map(obj => (
                            <InputGroup key={obj[0]}>
                                <InputLeftAddon children={obj[0]} />
                                {
                                    (obj[0] === 'picture') ? <Img px={5} src={obj[1].toString()} />
                                    : <Input defaultValue={obj[1].toString()} />
                                }
                            </InputGroup>
                        ))
                    }
                </VStack>
                <Box>
                    <Accordion defaultIndex={[3]} allowMultiple>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>Access Token</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            <Box w={600} as="pre">{JSON.stringify(accessToken ? jwtDecode(accessToken) : null, null, 2)}</Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'><Heading size='sm'>ID Token</Heading></Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                            <Box w={600} as="pre">{JSON.stringify(idToken ? jwtDecode(idToken) : null, null, 2)}</Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Box>
            </SimpleGrid>

    )
}
